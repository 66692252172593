import React, { useState, createContext, useEffect, useContext } from "react"
import {
  getRecentDonations,
  listenForRecurringDonationChanges,
} from "services/donations-service"
import { useRayze } from "./RayzeContext"
import { useOrganization } from "./OrganizationContext"

export const DonationsContext = createContext()

export const DonationsProvider = ({ children }) => {
  const { selectedOrg } = useRayze()
  const { orgs } = useOrganization()

  const [recentDonations, setRecentDonations] = useState([])
  const [getDonations, setGetDonations] = useState(false)
  const [ourDonations, setOurDonations] = useState([])
  const [ourRecurringDonations, setOurRecurringDonations] = useState([])
  const [selectedDonation, setSelectedDonation] = useState(null)

  //page
  const [search, setSearch] = useState("");
	const [filtered, setFiltered] = useState([]);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    if (!orgs?.length) return
    getRecentDonations(setRecentDonations, orgs)

    // const sub = listenForRecurringDonationChanges(
    //   selectedOrg,
    //   setOurRecurringDonations
    // )

    // return () => {
    //   sub()
    // }
  }, [orgs])

  useEffect(() => {
		let temp = [...recentDonations];
		if (search) {
			temp = temp.filter(
				(item) =>
					item.organization?.name
						.toLowerCase()
						.includes(search.toLowerCase()) ||
					item.user?.firstName.toLowerCase().includes(search.toLowerCase()) ||
					item.user?.lastName.toLowerCase().includes(search.toLowerCase())
			);
		}
		setFiltered(temp);
	}, [search, recentDonations]);



  return (
    <DonationsContext.Provider
      value={{
        recentDonations,
        setGetDonations,
        ourDonations,
        ourRecurringDonations,
        selectedDonation,
        setSelectedDonation,
        search,
        setSearch,
        filtered,
        setFiltered,
        rowsPerPage,
        setRowsPerPage,
        currentPage,
        setCurrentPage,
      }}
    >
      {children}
    </DonationsContext.Provider>
  )
}

export const useDonations = () => useContext(DonationsContext)
