import { firestore, functions } from "utils/firebase";

const stripeWalletId = "stripeWalletId";

export const getRayzeAccounts = (setAccounts) => {
    return firestore
        .collection("accounts")
        .onSnapshot(
            (querySnapshot) => {
                const accounts = [];
                querySnapshot.forEach((doc) => {
                    const data = doc.data();
                    const account = {
                        ...data,
                        id: doc.id,
                    }
                    accounts.push(account);
                });

                // get associated user in promise
                let promises = [];
                accounts.map((account) => {
                    promises.push(() => {
                        return new Promise((resolve, reject) => {
                            firestore
                                .collection("users")
                                .doc(account.customerId)
                                .get()
                                .then((userDoc) => {
                                    if (userDoc.exists) {
                                        account.user = userDoc.data();
                                        account.isUser = true;
                                        resolve();
                                    } else {
                                        //check if organization
                                        firestore
                                            .collection("organizations")
                                            .doc(account.customerId)
                                            .get()
                                            .then((orgDoc) => {
                                                if (orgDoc.exists) {
                                                    account.org = orgDoc.data();
                                                    account.isOrg = true;
                                                }
                                                resolve();
                                            })
                                            .catch((error) => {
                                                console.log("Error getting organization:", error);
                                                resolve();
                                            });
                                    }
                                })
                                .catch((error) => {
                                    console.log("Error getting user:", error);
                                    reject(error);
                                });
                        });
                    })
                });

                Promise.all(promises.map(p => p())).then(() => {
                    setAccounts(accounts);
                });
            },
            (e) => {
                console.log("getBusinessRayzeAccount error", e);
            }
        );

};

export const getRayzeAccountTransactions = (rayzeAccount, setWalletTransactions) => {
    return firestore
        .collection("accounts").doc(rayzeAccount?.id)
        .collection("transactions")
        .orderBy("date", "desc")
        .onSnapshot(
            (querySnapshot) => {
                const transactions = [];
                querySnapshot.forEach((doc) => {
                    const data = doc.data();
                    const transaction = {
                        ...data,
                        id: doc.id,
                    }
                    transaction.date = transaction.date.toDate();
                    transactions.push(transaction);
                });
                setWalletTransactions(transactions);
            },
            (e) => {
                console.log("getBusinessRayzeAccount error", e);
            }
        );
};

export const getWalletStripeAccount = async (setAccount) => {
    return functions.httpsCallable("getStripeWalletAccount")({ stripeWalletId }).then((res) => {
        const account = res?.data;
        account.amount = account.available[0].amount / 100;
        setAccount(res?.data);
    })
}