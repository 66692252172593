import React from "react";
import { useAuth } from "context/AuthContext";
import { Landing } from "screens/Auth/Landing";
import Sidebar from "components/Sidebar/Sidebar";
import { BrowserRouter as Router, Navigate } from "react-router-dom";
import { Route, Routes } from "react-router-dom";
import MainNavigator from "./MainNavigator";
import { Links } from "constants/Links";
import { Dimensions, View } from "react-native";
import { CausesProvider } from "context/CausesContext";
import { SkillsProvider } from "context/SkillsContext";
import { OrganizationProvider } from "context/OrganizationContext";
import { DonationsProvider } from "context/DonationsContext";
import { EventsProvider } from "context/EventContext";
import { DashboardProvider } from "context/DashboardContext";
import { UserProvider } from "context/UserContext";
import { RayzeProvider } from "context/RayzeContext";
import { CRMProvider } from "context/CRMContext";
import { PBCProvider } from "context/PBCContext";
import { FundraiserProvider } from "context/FundraiserContext";
import { WalletProvider } from "context/WalletContext";

export default function RootNavigator() {
	const authContext = useAuth();
	const { currentUser } = authContext;

	const { height } = Dimensions.get("window");

	return currentUser === null ? (
		<Router>
			<View
				style={{
					flex: 1,
					height,
					alignItems: "center",
					justifyContent: "center",
					paddingHorizontal: 24,
					paddingTop: 12
				}}
			>
				<Routes>
					<Route
						path={Links.Landing}
						element={<Landing />}
					/>
					<Route
						path={"/*"}
						element={
							<Navigate
								replace
								to={Links.Landing}
							/>
						}
					/>
				</Routes>
			</View>
		</Router>
	) : (
		<CausesProvider>
			<SkillsProvider>
				<RayzeProvider>
					<DashboardProvider>
						<OrganizationProvider>
							<DonationsProvider>
								<UserProvider>
									<EventsProvider>
										<FundraiserProvider>
											<WalletProvider>
												<CRMProvider>
													<PBCProvider>
														<Router>
															<Sidebar />
															<MainNavigator />
														</Router>
													</PBCProvider>
												</CRMProvider>
											</WalletProvider>
										</FundraiserProvider>
									</EventsProvider>
								</UserProvider>
							</DonationsProvider>
						</OrganizationProvider>
					</DashboardProvider>
				</RayzeProvider>
			</SkillsProvider>
		</CausesProvider>
	);
}
