import React, { useState, createContext, useEffect, useContext } from "react"
import {
  getRecentDonations,
  listenForRecurringDonationChanges,
} from "services/donations-service"
import { useRayze } from "./RayzeContext"
import { useOrganization } from "./OrganizationContext"
import { updateOrganization } from "services/organizations-service"
import { round } from "lodash"

export const DonationsContext = createContext()

export const DonationsProvider = ({ children }) => {
  const { selectedOrg } = useRayze()
  const { orgs } = useOrganization()

  const [recentDonations, setRecentDonations] = useState([])
  const [getDonations, setGetDonations] = useState(false)
  const [ourDonations, setOurDonations] = useState([])
  const [ourRecurringDonations, setOurRecurringDonations] = useState([])
  const [selectedDonation, setSelectedDonation] = useState(null)

  //page
  const [search, setSearch] = useState("");
  const [filtered, setFiltered] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    if (!orgs?.length) return
    getRecentDonations(setRecentDonations, orgs)

    // const sub = listenForRecurringDonationChanges(
    //   selectedOrg,
    //   setOurRecurringDonations
    // )

    // return () => {
    //   sub()
    // }
  }, [orgs])

  useEffect(() => {
    if (!recentDonations?.length) return
    
    //group recentDonations by orgId
    const groupedDonations = recentDonations.reduce((acc, donation) => {
      if (!acc[donation.orgId]) {
        acc[donation.orgId] = []
      }
      acc[donation.orgId].push(donation)
      return acc
    }, {})

    // console.log(groupedDonations)
    //loop over object
    const orgs = Object.keys(groupedDonations).map(orgId => {
      const donations = groupedDonations[orgId]
      const totalDonations = donations.reduce((acc, donation) => acc + (donation.amount), 0)
      const totalFees = donations.reduce((acc, donation) => acc + (donation?.fee || 0), 0)
      const totalNetDonations = totalDonations - totalFees
      return {
        orgId,
        name: donations[0].org?.name,
        totalDonations,
        totalFees,
        totalNetDonations,
        test: donations[0].org?.totalDonations,
        cot: donations[0]?.cot || false
      }
    })
    const notCot = orgs.filter(org => !org.cot && org?.name)
    console.log(notCot)
    // notCot.forEach(org => {
    //   // if (org?.totalDonations !== org?.test) {
    //     console.log(org?.orgId)
    //     updateOrganization({id:org.orgId}, { 
    //       totalDonations: round(org.totalDonations, 2),
    //       totalFees: round(org.totalFees,2),
    //       totalNetDonations: round(org.totalNetDonations,2)
    //      }, [], [], () => {})
    //   // }
    // })
    
    
    let temp = [...recentDonations];
    if (search) {
      temp = temp.filter(
        (item) =>
          item.organization?.name
            .toLowerCase()
            .includes(search.toLowerCase()) ||
          item.org?.name
            .toLowerCase()
            .includes(search.toLowerCase()) ||
          item.user?.firstName.toLowerCase().includes(search.toLowerCase()) ||
          item.user?.lastName.toLowerCase().includes(search.toLowerCase())
      );
    }
    //sum temp by donation amount
    let totalDonations = temp.reduce((acc, donation) => acc + (donation.amount-(donation.fee || 0)), 0)
    console.log(totalDonations)
    setFiltered(temp);
  }, [search, recentDonations]);



  return (
    <DonationsContext.Provider
      value={{
        recentDonations,
        setGetDonations,
        ourDonations,
        ourRecurringDonations,
        selectedDonation,
        setSelectedDonation,
        search,
        setSearch,
        filtered,
        setFiltered,
        rowsPerPage,
        setRowsPerPage,
        currentPage,
        setCurrentPage,
      }}
    >
      {children}
    </DonationsContext.Provider>
  )
}

export const useDonations = () => useContext(DonationsContext)
