import React, { useEffect, useState } from "react";
import { useOrganization } from "context/OrganizationContext";
import {
	ActivityIndicator,
	Dimensions,
	Image,
	ScrollView,
	StyleSheet,
	TouchableOpacity,
	View
} from "react-native-web";
import { Header } from "components/Header";
import { COLORS } from "constants/Colors";
import { useNavigate } from "react-router";
import { PrimaryText } from "components/Common/PrimaryText";
import { useDonations } from "context/DonationsContext";
import {
	deleteOrg,
	listenToOrg,
	sendRejectionEmail,
	updateOrganization
} from "services/organizations-service";
import { ConfirmModal } from "components/Modals/Confirmation";
import {
	Table,
	TableHead,
	TableBody,
	TableCell,
	TableRow
} from "@mui/material";
import { GiTrashCan } from "react-icons/gi";
import {
	deleteOrgDoc,
	deleteRayzeDoc,
	getOrgDocuments,
	getRayzeDocuments,
	uploadRayzeDoc
} from "services/document-service";
import * as FaIcons from "react-icons/fa";
import MuiButton from "components/Common/MuiButton";
import { Edit, Files, QRCode } from "assets/svgs";
import useMediaQuery from "hooks/useMediaQuery";
import { dollarFormatter } from "utils/helpers";

const { height } = Dimensions.get("window");

export const NonprofitDetail = () => {
  const navigate = useNavigate();
  const { selectedOrg: contextOrg, causes, members, orgs, setOrgs, filterOrgs, nextOrg, prevOrg } = useOrganization();
  const { ourRecurringDonations } = useDonations();
  const { getResponsiveValue } = useMediaQuery();

	const [selectedOrg, setSelectedOrg] = useState(null);
	const [orgFiles, setOrgFiles] = useState(null);
	const [rayzeFiles, setRayzeFiles] = useState(null);
	const [newFile, setNewFile] = useState(null);
	const [fileToDelete, setFileToDelete] = useState(null);
	const [loading, setLoading] = useState(false);
	const [showConfirmModal, setShowConfirmModal] = useState(false);

	const url = `https://donate.rayzeapp.com/orgs/${selectedOrg?.id}`;

	const baseUrl = "https://donate.rayzeapp.com/";

	const tabs =
		selectedOrg?.type === "nonprofit"
			? ["Upcoming Events", "Recent Donations"]
			: ["Upcoming Events"];

	const [activeTab, setActiveTab] = useState(tabs[0]);

	const statistics =
		selectedOrg?.type === "nonprofit"
			? [
					[
						{
							title: "Total Donations",
							amount: `${dollarFormatter.format(selectedOrg?.totalDonations) || 0}`
						},
						{
							title: "Monthly Recurring Amount",
							amount:
								ourRecurringDonations?.length > 0
									? `${dollarFormatter.format(ourRecurringDonations
											.map((x) => x.amount)
											.reduce((a, b) => {
												return a + b;
											}))}`
									: "$0"
						}
					],
					[
						{
							title: "Monthly Donors",
							amount: ourRecurringDonations?.length
						},

						{
							title: "Team Members",
							amount: members?.length
						}
					]
			  ]
			: [
					[
						{
							title: "Team Members",
							amount: members?.length
						}
					],
					[
						{
							title: "Followers",
							amount: selectedOrg?.followers || 0
						}
					]
			  ];

	const userDetails = [
		{
			title: "Website",
			value: selectedOrg?.website
		},
		{
			title: "Public Address",
			value: `${
				selectedOrg?.address?.line1 ? selectedOrg?.address?.line1 + "\n" : ""
			}${selectedOrg?.address?.city}, ${
				selectedOrg?.address?.state + (selectedOrg?.address?.zipCode ? "," : "")
			} ${selectedOrg?.address?.zipCode || ""} `,
			capitalize: true
			// value: "1234 Fake Street Lumberton, SC 28358",
		},
		{
			title: "Mailing Address",
			value: selectedOrg?.addressMailing,
			capitalize: true
			// value: "1234 Fake Street Lumberton, SC 28358",
		},
		{
			title: "EIN",
			value: selectedOrg?.ein
		},
		{
			title: "Phone",
			value: selectedOrg?.phone
		},
		{
			title: "Email",
			value: selectedOrg?.adminEmail
		}
	];

	useEffect(() => {
		if (!contextOrg) return;
		const sub = listenToOrg(contextOrg, setSelectedOrg);
		return () => {
			sub();
		};
	}, [contextOrg]);

  useEffect(() => {
    if (selectedOrg) {
      const findIndex = orgs.findIndex((x) => x.id === selectedOrg.id);
      orgs[findIndex] = { ...selectedOrg };
      setOrgs([...orgs]);
      // filterOrgs(orgs)
      getOrgDocuments(selectedOrg?.id, setOrgFiles);
      getRayzeDocuments(selectedOrg?.id, setRayzeFiles);
    }
  }, [selectedOrg]);

	function gotOrgFiles(files) {
		setOrgFiles(files);
		if (files.length > 0 && !selectedOrg?.verified) {
			updateOrganization(
				selectedOrg,
				{ pendingVerification: true },
				[],
				[],
				() => {}
			);
		}
	}

	function onFileSelected(e) {
		if (e.target.files && e.target.files.length > 0) {
			setNewFile(e.target.files[0]);
		}
	}

	function onSave() {
		setLoading(true);
		uploadRayzeDoc(selectedOrg?.id, newFile, onSuccessSave);
	}

	function onSuccessSave() {
		setNewFile(null);
		setLoading(false);
		getRayzeDocuments(selectedOrg?.id, setRayzeFiles);
		window.alert("File uploaded successfully");
	}

	function downloadFile(file) {
		const link = document.createElement("a");
		link.target = "_blank";
		link.href = file.downloadURL;
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	}

	function deleteFile(file) {
		setFileToDelete(file);
	}

	function confirmDeleteFile() {
		const findIndex = rayzeFiles.findIndex(
			(x) => x?.timeCreated === fileToDelete.timeCreated
		);
		if (findIndex > -1) {
			return deleteRayzeDoc(
				selectedOrg?.id,
				fileToDelete,
				onSuccessRayzeDelete
			);
		}
		return deleteOrgDoc(selectedOrg?.id, fileToDelete, onSuccessDelete);
	}

	function onSuccessRayzeDelete() {
		setFileToDelete(null);
		setLoading(false);
		getRayzeDocuments(selectedOrg?.id, setRayzeFiles);
		window.alert("File deleted successfully");
	}

	function onSuccessDelete() {
		setFileToDelete(null);
		setLoading(false);
		getOrgDocuments(selectedOrg?.id, setOrgFiles);
		window.alert("File deleted successfully");
	}

	function onDeleteOrg() {
		const findIndex = orgs.findIndex((x) => x.id === selectedOrg.id);
		orgs.splice(findIndex, 1);

		deleteOrg(selectedOrg, () => navigate(-1));
	}

	const copyLink = async () => {
		try {
			await navigator.clipboard.writeText(
				selectedOrg?.customUrl ? baseUrl + selectedOrg?.customUrl : url
			);
			// setOpenSnackbar("URL copied.");
		} catch (err) {
			console.error("Failed to copy: ", err);
		}
	};

	const styles = getStyles(getResponsiveValue);

	return (
		<ScrollView style={{ width: "100%", maxHeight: height - 80 }}>
			{!selectedOrg || loading ? (
				<View style={{ flexGrow: 1, height: height - 100 }}>
					<ActivityIndicator
						animating
						size={"large"}
						style={styles.activity}
						color={COLORS.primary.lightBlue}
					/>
				</View>
			) : (
				<>
					<View style={styles.container}>
						<Header
							title={selectedOrg?.name}
							onBack={() => navigate(-1)}
							onRight={() => setShowConfirmModal(true)}
							rightText={"Delete"}
							onNext={nextOrg}
							onPrev={prevOrg}
							// exportData={formatMostValuableItemsReport(filtered)}
						/>

						{/** ROW 1 */}
						<View style={[styles.info, { marginTop: 24 }]}>
							<View style={styles.statsPhoto}>
								<View style={styles.cover_img_v}>
									<Image
										style={{
											width: "100%",
											aspectRatio: 16 / 9,
											borderRadius: 12
										}}
										source={{ uri: selectedOrg?.coverPhoto }}
										resizeMode={"cover"}
									/>
								</View>
							</View>
							<View style={styles.stats}>
								{statistics.map((row, i) => {
									return (
										<View
											style={{ justifyContent: "space-between", gap: 16 }}
											key={i}
										>
											{row.map((item, i) => {
												const { title, amount } = item;
												return (
													<View
														style={styles.statContainer}
														key={i}
													>
														<PrimaryText
															fontSize={18}
															fontWeight={400}
															style={styles.header}
														>
															{title}
														</PrimaryText>
														<PrimaryText
															fontSize={24}
															fontWeight={700}
															style={styles.header}
														>
															{amount}
														</PrimaryText>
													</View>
												);
											})}
										</View>
									);
								})}
							</View>
						</View>

						{/** ROW 2 */}
						<View style={styles.info}>
							<View style={styles.infoContainer}>
								{userDetails.map((u, i) => {
									const { title, value } = u;
									if (!value) return null;
									return (
										<View
											style={styles.infoTextRow}
											key={i}
										>
											<View
												style={{ marginRight: 12 }}
												className="text-right mr-4"
											>
												<PrimaryText
													fontSize={14}
													fontWeight={400}
													style={{
														width: getResponsiveValue({ xs: null, sm: 180 }),
														textAlign: "right"
													}}
												>
													{title}
												</PrimaryText>
											</View>
											<PrimaryText
												fontSize={16}
												fontWeight={600}
												style={u?.capitalize && { textTransform: "capitalize" }}
											>
												{value}
											</PrimaryText>
										</View>
									);
								})}
							</View>

							<ScrollView style={styles.causesContainer}>
								<PrimaryText
									fontSize={16}
									fontWeight={600}
								>
									{"Causes"}
								</PrimaryText>
								<View style={styles.causes}>
									{causes.map((item) => (
										<View
											style={styles.cause}
											key={item.id}
										>
											<PrimaryText
												fontSize={14}
												fontWeight={400}
												style={{ color: COLORS.primary.lightBlue }}
											>
												{item.name}
											</PrimaryText>
											{/* <FaIcons.FaTimes /> */}
										</View>
									))}
								</View>
							</ScrollView>
						</View>

						{/** ROW 3 */}
						<View style={styles.info}>
							<View style={styles.infoContainer}>
								<PrimaryText
									fontSize={16}
									fontWeight={600}
								>
									{"501(c)(3) Status"}
								</PrimaryText>
								<View style={styles.verificationStatusContainer}>
									<TouchableOpacity
										onPress={() =>
											updateOrganization(
												selectedOrg,
												{ verified: true, pendingVerification: false },
												[],
												[],
												() => {}
											)
										}
										style={[
											styles.cause,
											{
												borderColor: selectedOrg?.verified
													? COLORS.primary.lightBlue
													: COLORS.primary.lightGray,
												height: 40
											}
										]}
									>
										<PrimaryText
											fontSize={14}
											fontWeight={400}
											style={{
												color: selectedOrg?.verified
													? COLORS.primary.lightBlue
													: COLORS.primary.lightGray
											}}
										>
											Verified
										</PrimaryText>
									</TouchableOpacity>
									<TouchableOpacity
										onPress={() =>
											updateOrganization(
												selectedOrg,
												{ verified: false },
												[],
												[],
												() => {}
											)
										}
										style={[
											styles.cause,
											{
												borderColor: !selectedOrg?.verified
													? COLORS.primary.lightBlue
													: COLORS.primary.lightGray,
												height: 40
											}
										]}
									>
										<PrimaryText
											fontSize={14}
											fontWeight={400}
											style={{
												color: !selectedOrg?.verified
													? COLORS.primary.lightBlue
													: COLORS.primary.lightGray
											}}
										>
											Unverified
										</PrimaryText>
									</TouchableOpacity>
									<TouchableOpacity
										onPress={() => {
											setLoading(true);
											sendRejectionEmail(
												selectedOrg,
												selectedOrg?.adminEmail,
												() => {
													updateOrganization(
														selectedOrg,
														{ pendingVerification: false },
														[],
														[],
														() => {}
													);
													setLoading(false);
												}
											);
										}}
										style={[
											styles.cause,
											{
												borderColor: !selectedOrg?.pendingVerification
													? COLORS.primary.lightGray
													: COLORS.primary.error,
												height: 40
											}
										]}
										disabled={!selectedOrg?.pendingVerification}
									>
										<PrimaryText
											fontSize={14}
											fontWeight={400}
											style={{
												color: !selectedOrg?.pendingVerification
													? COLORS.primary.lightGray
													: COLORS.primary.error
											}}
										>
											Reject
										</PrimaryText>
									</TouchableOpacity>
								</View>

								{selectedOrg?.verified && !!selectedOrg?.stripeID && (
									<View style={styles.infoTextRow}>
										<View
											style={{ marginRight: 12 }}
											className="text-right mr-4"
										>
											<PrimaryText
												fontSize={14}
												fontWeight={400}
												// style={{ width: 180, textAlign: "right" }}
											>
												{"Stripe Account ID:"}
											</PrimaryText>
										</View>
										<PrimaryText
											fontSize={16}
											fontWeight={600}
										>
											{selectedOrg?.stripeID}
										</PrimaryText>
									</View>
								)}

								<View style={[styles.infoTextRow, { paddingTop: 24 }]}>
									<View
										style={{ marginRight: 12 }}
										className="text-right mr-4"
									>
										<PrimaryText
											fontSize={14}
											fontWeight={400}
											// style={{ width: 180, textAlign: "right" }}
										>
											{"Donation Link:"}
										</PrimaryText>
									</View>
									<PrimaryText
										fontSize={16}
										fontWeight={600}
										style={{ flex: 1 }}
										numberOfLines={2}
									>
										{selectedOrg?.customUrl
											? baseUrl + selectedOrg?.customUrl
											: url}
									</PrimaryText>
								</View>
								<MuiButton
									title="Copy"
									variant="outlined"
									color="secondary"
									startIcon={
										<Files
											fill={COLORS.primary.darkBlue}
											width={16}
										/>
									}
									sx={muiStyles.copyLink}
									onPress={copyLink}
								/>
							</View>
							<View style={[styles.infoContainer]}>
								<PrimaryText
									fontSize={16}
									fontWeight={600}
								>
									{"Verification Docs"}
								</PrimaryText>
								<Table
									className="table"
									style={{ borderTopLeftRadius: 12, overflow: "hidden" }}
								>
									<TableHead>
										<TableRow>
											<TableCell>File</TableCell>
											<TableCell>Uploaded At</TableCell>
											<TableCell></TableCell>
											<TableCell></TableCell>
										</TableRow>
									</TableHead>

									<TableBody>
										{orgFiles &&
											orgFiles.map((file, i) => {
												return (
													<TableRow key={i}>
														<TableCell>{file?.name}</TableCell>
														<TableCell>
															{new Date(file?.timeCreated).toDateString()}
														</TableCell>
														<TableCell>
															<TouchableOpacity
																onPress={() => downloadFile(file)}
															>
																<FaIcons.FaDownload
																	size={20}
																	color={COLORS.primary.green}
																/>
															</TouchableOpacity>
														</TableCell>
														<TableCell>
															<TouchableOpacity
																onPress={() => deleteFile(file)}
															>
																<GiTrashCan
																	size={20}
																	color={COLORS.primary.error}
																/>
															</TouchableOpacity>
														</TableCell>
													</TableRow>
												);
											})}
									</TableBody>
								</Table>
							</View>
						</View>

						{/** ROW 4 */}
						<View style={styles.info}>
							<View style={styles.infoContainer}>
								<View style={{ flexDirection: "row", alignItems: "center" }}>
									<PrimaryText
										fontSize={16}
										fontWeight={600}
									>
										{"Rayze Private Docs"}
									</PrimaryText>
									<TouchableOpacity
										style={[styles.button, { marginLeft: 24 }]}
										onPress={() =>
											document.getElementById("choose-file").click()
										}
									>
										<input
											id="choose-file"
											type="file"
											onChange={onFileSelected}
											style={{ display: "none" }}
										/>
										<PrimaryText
											fontSize={14}
											fontWeight={300}
											style={{ color: COLORS.primary.white, marginRight: 12 }}
										>
											Upload Document
										</PrimaryText>
										<FaIcons.FaPlus
											size={16}
											color={COLORS.primary.white}
										/>
									</TouchableOpacity>
								</View>
								{newFile && (
									<View
										style={{
											flexDirection: "row",
											alignItems: "center",
											margin: 12
										}}
									>
										<PrimaryText
											fontSize={14}
											fontWeight={400}
											style={{ marginRight: 12 }}
										>
											{newFile?.name}
										</PrimaryText>
										<TouchableOpacity
											style={styles.saveButton}
											onPress={onSave}
										>
											<PrimaryText
												fontSize={14}
												fontWeight={300}
												style={{ color: COLORS.primary.white }}
											>
												Save
											</PrimaryText>
										</TouchableOpacity>
									</View>
								)}
								<Table
									className="table"
									style={{
										borderTopLeftRadius: 12,
										overflow: "hidden",
										marginTop: 12
									}}
								>
									<TableHead>
										<TableRow>
											<TableCell>File</TableCell>
											<TableCell>Uploaded At</TableCell>
											<TableCell></TableCell>
											<TableCell></TableCell>
										</TableRow>
									</TableHead>

									<TableBody>
										{rayzeFiles &&
											rayzeFiles.map((file, i) => {
												return (
													<TableRow key={i}>
														<TableCell>{file?.name}</TableCell>
														<TableCell>
															{new Date(file?.timeCreated).toDateString()}
														</TableCell>
														<TableCell>
															<TouchableOpacity
																onPress={() => downloadFile(file)}
															>
																<FaIcons.FaDownload
																	size={20}
																	color={COLORS.primary.green}
																/>
															</TouchableOpacity>
														</TableCell>
														<TableCell>
															<TouchableOpacity
																onPress={() => deleteFile(file)}
															>
																<GiTrashCan
																	size={20}
																	color={COLORS.primary.error}
																/>
															</TouchableOpacity>
														</TableCell>
													</TableRow>
												);
											})}
									</TableBody>
								</Table>
							</View>
						</View>

						{/* <View style={styles.tabs}>
              {tabs.map((t, i) => {
                return (
                  <TouchableOpacity
                    key={i}
                    style={[
                      styles.tabsButton,
                      t === activeTab && styles.tabActive,
                    ]}
                    onPress={() => setActiveTab(t)}
                  >
                    <PrimaryText
                      fontSize={14}
                      fontWeight={700}
                      style={[
                        styles.tabText,
                        t === activeTab && styles.tabTextActive,
                      ]}
                    >
                      {t}
                    </PrimaryText>
                  </TouchableOpacity>
                )
              })}
            </View> */}

						{/* {activeTab === tabs[0] ? (
            <EventsTab />
          ) : activeTab === tabs[1] ? (
            <DonationsTab />
          ) : activeTab === "Team Members" ? (
            <MembersTab />
          ) : activeTab === "Inspiring" ? (
            <InspiringTab />
          ) : null} */}
					</View>

					{showConfirmModal && (
						<ConfirmModal
							title={"Delete Organization"}
							message={"Are you sure?"}
							onConfirm={onDeleteOrg}
							onCancel={() => setShowConfirmModal(false)}
						/>
					)}

					{!!fileToDelete && (
						<ConfirmModal
							title={"Delete file"}
							message={"Are you sure?"}
							onConfirm={confirmDeleteFile}
							onConfirmText={"Yes"}
							onCancel={() => setFileToDelete(null)}
						/>
					)}
				</>
			)}
		</ScrollView>
	);
};

const muiStyles = {
	copyLink: {
		height: 29,
		fontSize: 14,
		fontWeight: 700,
		marginRight: 20,
		marginTop: 14,
		marginBottom: 13,
		paddingHorizontal: 12,
		width: 100
	},
	edit: {
		height: 23,
		fontSize: 12,
		marginLeft: 10
	},
	cancel: {
		height: 23,
		fontSize: 12,
		fontWeight: 700
	},
	save: {
		height: 23,
		fontSize: 12,
		fontWeight: 700,
		marginLeft: 12,
		marginRight: 12
	}
};

const getStyles = (getResponsiveValue) =>
	StyleSheet.create({
		activity: {
			flex: 1,
			width: "100%",
			backgroundColor: COLORS.primary.white
		},
		container: {
			flex: 1,
			width: "100%",
			paddingVertical: 20,
			backgroundColor: COLORS.primary.white
		},
		header: {
			color: COLORS.primary.darkBlue
		},
		cover_img_v: {
			width: "100%",
			aspectRatio: 16 / 9,
			borderRadius: 12,
			overflow: "hidden"
		},
		statsPhoto: {
			flex: getResponsiveValue({ xs: 1, sm: 0.4 }),
			height: "100%"
		},
		stats: {
			flex: 1,
			flexDirection: getResponsiveValue({ xs: "column", sm: "row" }),
			padding: 27,
			justifyContent: "space-around",
			backgroundColor: COLORS.primary.offWhite,
			borderRadius: 12,
			gap: 16
		},
		statContainer: {
			alignItems: "center"
		},
		info: {
			flexDirection: getResponsiveValue({ xs: "column", md: "row" }),
			marginBottom: 16,
			maxHeight: getResponsiveValue({ xs: null, md: 300 }),
			gap: 16
		},
		infoContainer: {
			flex: 1,
			padding: 20,
			justifyContent: "space-around",
			backgroundColor: COLORS.primary.offWhite,
			borderRadius: 12
		},
		infoTextRow: {
			flexDirection: "row",
			alignItems: "center",
			marginVertical: 6
		},
		causesContainer: {
			flex: 1,
			// height: 200,
			padding: 20,
			backgroundColor: COLORS.primary.offWhite,
			borderRadius: 12
		},
		causes: {
			flex: 1,
			flexDirection: "row",
			flexWrap: "wrap",
			paddingBottom: 20,
			paddingTop: 12
		},
		cause: {
			flexDirection: "row",
			alignItems: "center",
			paddingVertical: 4,
			paddingHorizontal: 8,
			marginRight: 8,
			marginBottom: 10,
			borderRadius: 4,
			borderWidth: 1,
			borderColor: COLORS.primary.lightBlue
		},
		verificationStatusContainer: {
			flex: 1,
			flexDirection: "row",
			paddingBottom: 20,
			paddingTop: 12
		},
		tabs: {
			flexDirection: "row",
			alignItems: "flex-start"
		},
		tabsButton: {
			width: 168,
			height: 36,
			backgroundColor: COLORS.primary.gray,
			borderTopLeftRadius: 12,
			borderTopRightRadius: 12,
			marginRight: 12,
			alignItems: "center",
			justifyContent: "center"
		},
		tabActive: {
			backgroundColor: COLORS.primary.offWhite,
			color: "black"
		},
		tabText: {
			textAlign: "center",
			color: COLORS.primary.white,
			fontWeight: "500"
		},
		tabTextActive: {
			color: COLORS.primary.darkBlue,
			fontWeight: "700"
		},
		button: {
			flexDirection: "row",
			backgroundColor: COLORS.primary.lightBlue,
			width: 200,
			justifyContent: "center",
			alignItems: "center",
			padding: 8,
			// margin: 12,
			borderRadius: 6
		},
		saveButton: {
			flexDirection: "row",
			backgroundColor: COLORS.primary.green,
			// width: 200,
			justifyContent: "center",
			alignItems: "center",
			padding: 8,
			margin: 12,
			borderRadius: 6
		}
	});
